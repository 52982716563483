import { EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY, SpecialHandlingIndMappingEntity } from "domain/entity/SpecialHandlingIndMapping/SpecialHandlingIndMappingEntity";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import _ from "lodash";
import { SpecialHandlingIndMappingModel } from "presentation/model/SpecialHandlingIndMapping/SpecialHandlingIndMappingModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface SpecialHandlingIndMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<SpecialHandlingIndMappingModel>> | ((value: SetStateAction<SpecialHandlingIndMappingModel>) => void),
    ],
    specialHandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    parameterDetailRepo: ParameterDetailRepository,
}

export const SpecialHandlingIndMappingVM = ({ dispatch, specialHandlingIndMappingRepo, parameterDetailRepo
}: SpecialHandlingIndMappingVMProps) => {
    const [specialHandlingIndMappingDispatch] = dispatch;

    const loadDropdownOption = async () => {


        await parameterDetailRepo.getAllParameterDtlsByParameterCode("SP_HANDLING_CODE").then(
            specialHandlingIndList => {
                const specialHandlingIndDropdownOption = specialHandlingIndList?.map((specialHandlingInd) => ({
                    dropdownLabel: specialHandlingInd.parameterDtlCode,
                    tagLabel: specialHandlingInd.parameterDtlCode,
                    value: specialHandlingInd.parameterDtlCode,
                })) ?? []

                specialHandlingIndMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specialHandlingDropdownOptions: specialHandlingIndDropdownOption
                    }
                }))
            }
        )


    }

    const searchAllspecialHandlingIndMappingList = async () => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: { ...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY },
                currentEditRow: { ...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows: [],
                specialHandlingIndMappingList: [],
            }
        })
        await specialHandlingIndMappingRepo.searchAllSpecialHandlingIndMappingList().then(data => {
            if (data) {
                specialHandlingIndMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        specialHandlingIndMappingList: data,
                    }
                })
            } else {
                specialHandlingIndMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        specialHandlingIndMappingList: [],
                    }
                })
            }
        })
    }

    // const onRowDrag = (rows: SpecialHandlingIndMappingEntity[]) => {
    //     specialHandlingIndMappingDispatch(prevState =>{ 
    //         const prevRows = prevState.specialHandlingIndMappingList;
    //         const toChangedRows = rows?.map((e, index) => ({...e,tempPriority: prevRows[index].priority}))?.filter(e=>e.tempPriority!==e.priority);

    //         return ({
    //             ...prevState,
    //             specialHandlingIndMappingList: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }

    const onApply = async (updatedRows: SpecialHandlingIndMappingEntity[]) => {

        return await specialHandlingIndMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows: SpecialHandlingIndMappingEntity[]) => {

        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                // specialHandlingIndMappingList: [...allRows],
                selectedRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey === 'specialHandlingCodeList' && !val) {
            val = [];
        }
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: SpecialHandlingIndMappingEntity) => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: SpecialHandlingIndMappingEntity) => {
        return specialHandlingIndMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY },
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }
    const onResetClick = async (isAdd: boolean) => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd ? { ...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY } : prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        specialHandlingIndMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    return {
        onCheckboxChange: onCheckboxChange,
        onResetClick: onResetClick,
        onEditClick: onEditClick,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        // onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllspecialHandlingIndMappingList: searchAllspecialHandlingIndMappingList,
    }
}